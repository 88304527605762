import React from 'react'

class Comfortaa extends React.Component {
  render() {
    const {
      props,
    } = this

    const {
      children,
      style,
    } = props

    return (
      <div
        style={Object.assign({
          fontFamily: 'Comfortaa',
          fontSize: '3.5rem',
          fontWeight: 'bold',
          paddingLeft: '3rem',
          paddingRight: '3rem',
        }, style)}
      >
        {children}
      </div>
    )
  }
}

export default Comfortaa
