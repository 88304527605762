import React from 'react'

class Tagline extends React.Component {
  render() {
    const {
      props,
    } = this

    const {
      children,
    } = props

    return (
      <p
        style={{
          fontSize: '2.5rem',
          marginTop: '2rem',
          paddingLeft: '3rem',
          paddingRight: '3rem',
        }}
      >
        {children}
      </p>
    )
  }
}

export default Tagline
