import Markdown from 'react-markdown'
import React from 'react'
import text from './text'

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <Markdown
        escapeHtml={false}
        source={text}
      />
    )
  }
}

export default PrivacyPolicy
