import React from 'react'
import deepPurple from '@material-ui/core/colors/deepPurple'
import discover from './discover.jpg'

class Features extends React.Component {
  static Item = class Item extends React.Component {
    render() {
      const {
        props,
      } = this

      const {
        children,
        icon,
        selected,
        title,
      } = props

      return (
        <div
          style={{
            alignItems: 'center',
            borderRadius: '1rem',
            boxShadow: selected ? '0 0 1rem #0002' : null,
            color: selected ? deepPurple[500] : '#777',
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 0,
            fontSize: '1.25rem',
            marginBottom: '0.5rem',
            marginLeft: '2rem',
            marginTop: '0.5rem',
            padding: '1rem',
            textAlign: 'left',
          }}
        >
          <span
            className={`mdi mdi-${icon}`}
            style={{
              fontSize: '3rem',
              marginRight: '1rem',
            }}
          />
          <div>
            <b>
              {children}
            </b>
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '3rem',
          marginTop: '2rem',
        }}
      >
        <img
          alt="Discover"
          src={discover}
          style={{
            width: '25rem',
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Features.Item
            icon="gamepad"
          >
            Create Your
            <br />
            Athlete Profile
          </Features.Item>
          <Features.Item
            icon="bell-ring"
          >
            Live Tournament
            <br />
            Notifications
          </Features.Item>
          <Features.Item
            icon="map-search"
            selected
          >
            Discover
            <br />
            Athletes & Events
          </Features.Item>
          <Features.Item
            icon="video-vintage"
          >
            Watch Live
            <br />
            Streams & Video
          </Features.Item>
          <Features.Item
            icon="bullhorn"
          >
            Share Your Ideas
            <br />
            & Accomplishments
          </Features.Item>
        </div>
      </div>
    )
  }
}

export default Features
