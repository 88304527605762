import React from 'react'

class Section extends React.Component {
  render() {
    const {
      props,
    } = this

    const {
      children,
      style,
    } = props

    return (
      <div
        style={Object.assign({
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          overflowX: 'hidden',
          padding: '8rem 0',
        }, style)}
      >
        {children}
      </div>
    )
  }
}

export default Section
