import React from 'react'
import indigo from '@material-ui/core/colors/indigo'
import Section from '../Section'

class GradientBackground extends React.Component {
  static start = {
    blue: indigo.A400,
    purple: '#2c0e7a',
  }

  static end = {
    blue: indigo.A700,
    purple: '#7e57c2',
  }

  render() {
    const {
      end,
      start,
    } = GradientBackground

    const {
      props,
    } = this

    const {
      children,
      color = 'purple',
      style,
    } = props

    return (
      <Section
        style={Object.assign({
          background: `linear-gradient(to right, ${start[color]}, ${end[color]}`,
          color: '#fff',
        }, style)}
      >
        {children}
      </Section>
    )
  }
}

export default GradientBackground
