import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Slide,
} from '@material-ui/core'
import React from 'react'
import {
  deepPurple,
  grey,
} from '@material-ui/core/colors'
import PrivacyPolicy from './PrivacyPolicy'
import Comfortaa from './Comfortaa'
import Features from './Features'
import GradientBackground from './GradientBackground'
import Section from './Section'
import Tagline from './Tagline'
import google from './google.png'
import apple from './apple.png'
import ss1 from './ss-1.png'
import ss2 from './ss-2.jpg'
import ss3 from './ss-3.jpg'
import vods from './vods.png'
import ranking from './ranking.png'
import integrations from './integrations.png'
import './App.css'

function App() {
  const isPrivacyPolicy = window.location.href.includes('privacy-policy')

  const [open, setOpen] = React.useState(isPrivacyPolicy)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <div
      className="App"
      style={{
        fontWeight: 300,
        textAlign: 'center',
      }}
    >
      <Dialog
        onClose={handleClose}
        open={open}
      >
        <DialogTitle>Privacy Policy</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <PrivacyPolicy />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <GradientBackground
        style={{
          marginBottom: '4rem',
          overflow: 'visible',
          overflowX: 'visible',
        }}
      >
        <Slide
          in
          timeout={350}
        >
          <Comfortaa
            style={{
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            athletes.gg
          </Comfortaa>
        </Slide>
        <Fade
          in
          timeout={350}
          style={{
            transitionDelay: '500ms',
          }}
        >
          <div
            style={{
              fontSize: '4rem',
              marginTop: '1rem',
              padding: '1rem',
            }}
          >
            The #1 app for esports athletes and fans.
          </div>
        </Fade>
        <Fade
          in
          style={{
            transitionDelay: '1000ms',
          }}
          timeout={350}
        >
          <div
            style={{
              marginTop: '3rem',
            }}
          >
            <a
              href="https://apps.apple.com/us/app/athletes-gg/id1449196852"
            >
              <img
                alt="Download on the App Store"
                src={apple}
                style={{
                  height: '4rem',
                  margin: '0.5rem',
                }}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=gg.athletes.app"
            >
              <img
                alt="Get it on Google Play"
                src={google}
                style={{
                  height: '4rem',
                  margin: '0.5rem',
                }}
              />
            </a>
          </div>
        </Fade>
        <div
          style={{
            alignItems: 'center',
            marginTop: '3rem',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '-15rem',
            maxWidth: 'calc(100% - 2rem)',
            overflow: 'hidden',
            padding: '1rem',
            paddingTop: '3rem',
          }}
        >
          <Slide
            direction="up"
            in
            timeout={1000}
          >
            <img
              alt="Nairo's athletes.gg profile."
              src={ss1}
              style={{
                borderRadius: '1em',
                boxShadow: '0 0 1rem #00000022',
                marginRight: '-3rem',
                maxWidth: '23rem',
                width: '45vw',
              }}
            />
          </Slide>
          <Slide
            direction="up"
            in
            timeout={500}
          >
            <img
              alt="Nairo's athletes.gg profile."
              className="bounce"
              src={ss2}
              style={{
                borderRadius: '1em',
                boxShadow: '0 0 1rem #00000066',
                maxWidth: '25rem',
                width: '50vw',
                zIndex: 100,
              }}
            />
          </Slide>
          <Slide
            direction="up"
            in
            timeout={1500}
          >
            <img
              alt="Nairo's athletes.gg profile."
              src={ss3}
              style={{
                borderRadius: '1em',
                boxShadow: '0 0 1rem #00000022',
                marginLeft: '-3rem',
                maxWidth: '23rem',
                width: '45vw',
              }}
            />
          </Slide>
        </div>
      </GradientBackground>
      <Section>
        <Comfortaa
          style={{
            color: deepPurple[500],
          }}
        >
          Never miss a match or stream
        </Comfortaa>
        <Tagline>
          We pull together and organize all the information for you.
        </Tagline>
        <Features />
        <img
          alt="Integrations"
          src={integrations}
          style={{
            marginTop: '2rem',
            maxWidth: '90vw',
          }}
        />
      </Section>
      <GradientBackground>
        <Comfortaa>Universal Esports Ranking</Comfortaa>
        <Tagline>
          From Sonicfox, to the up-and-coming 8-year-old prodigy, to
          <b> you </b>
          &mdash; we&rsquo;re ranking every esports athlete in the world.
        </Tagline>
        <img
          alt="What's your esports ranking?"
          src={ranking}
        />
        <Button
          style={{
            display: 'none',
            borderColor: '#fff',
            borderRadius: '1rem',
            borderWidth: '0.1rem',
            color: '#fff',
            fontSize: '1.25rem',
            fontWeight: 300,
            marginTop: '4rem',
            padding: '1rem',
            textTransform: 'none',
          }}
          variant="outlined"
        >
          Find out where you rank
        </Button>
      </GradientBackground>
      <GradientBackground
        color="blue"
      >
        <Comfortaa>Are you an esports</Comfortaa>
        <Comfortaa
          style={{
            fontSize: '2rem',
            marginTop: '3rem',
          }}
        >
          team,
          <br />
          league,
          <br />
          sponsor,
          <br />
          producer,
          <br />
          or tech company?
        </Comfortaa>
        <Button
          href="mailto:hello@athletes.gg"
          style={{
            borderColor: '#fff',
            borderWidth: '0.1rem',
            color: '#fff',
            fontSize: '1.25rem',
            marginTop: '4rem',
            padding: '1rem',
          }}
          variant="outlined"
        >
          Let's work together
        </Button>
      </GradientBackground>
      <Section
        style={{
          backgroundColor: grey[100],
          color: grey[700],
          padding: '1.5rem 0',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            maxWidth: '70rem',
            width: '100%',
          }}
        >
          <div
            style={{
              flexGrow: 1,
              textAlign: 'left',
            }}
          >
            <a
              href="javascript:void(0)"
              onClick={handleOpen}
              style={{
                color: grey[700],
                padding: '0 1rem',
              }}
            >
              <b>Privacy Policy</b>
            </a>
            <a
              href="mailto:hello@athletes.gg"
              style={{
                color: grey[700],
                padding: '0 1rem',
              }}
            >
              <b>Contact Us</b>
            </a>
          </div>
          <div
            style={{
              fontSize: '1.25rem',
            }}
          >
            <a
              href="https://twitter.com/athletesgg"
            >
              <span
                className="mdi mdi-twitter"
                style={{
                  color: grey[700],
                  padding: '0 0.25rem',
                }}
              />
            </a>
            <a
              href="https://facebook.com/athletesgg"
            >
              <span
                className="mdi mdi-facebook"
                style={{
                  color: grey[700],
                  padding: '0 0.25rem',
                }}
              />
            </a>
            <a
              href="https://instagram.com/athletesgg"
            >
              <span
                className="mdi mdi-instagram"
                style={{
                  color: grey[700],
                  padding: '0 0.25rem',
                }}
              />
            </a>
            <a
              href="https://linkedin.com/company/athletesgg"
            >
              <span
                className="mdi mdi-linkedin"
                style={{
                  color: grey[700],
                  padding: '0 0.25rem',
                }}
              />
            </a>
          </div>
        </div>
      </Section>
    </div>
  )
}

export default App
